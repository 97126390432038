import React from "react"

import { Styleguide as Page } from "../components/Styleguide/Styleguide"

import { useHotjar } from '../hooks/useHotjar'

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("styleguide_page")

  return <Page {...props} {...data} />
}
export default Component
