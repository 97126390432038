import tw, { styled } from "twin.macro"

const styles = {
  size: {
    default: tw`border-t border-black max-w-full w-full mx-auto`,
  },
  spacing: {
    default: tw`my-4`,
  },
}

export const StyledRule = styled.hr<HrProps>`
  ${({ size }) => (size && styles.size[size]) || styles.size[`default`]}
  ${({ spacing }) => (spacing && styles.spacing[spacing]) || (spacing && styles.spacing[`default`])}
`

type HrProps = {
  size: "default"
  spacing: "default"
}
