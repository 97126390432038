import React from "react"
import tw, { styled } from "twin.macro"

import { Icon } from "../Icon/Icon"
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  SubTitle,
  XXLargeBody,
  XLargeBody,
  LargeBody,
  Body,
  SmallBody,
  TinyBody,
} from "../Styled/Text"
import { StyledButton, UnderlinedButton } from "../Styled/Button"
import { Swatch } from "./Swatch"
import { StyledContainer } from "../Styled/Container"
import { StyledRule } from "../Styled/Rule"

const Container = tw(StyledContainer)`py-2-4`

const Button = tw(StyledButton)`m-1-6`

const Colours = tw.div`flex flex-wrap`

const Icons = styled.div<IconsProp>`
  ${tw`grid`}
  ${({ size }) => {
    if (size === "sm") {
      return tw`grid-cols-8 gap-2-4`
    }
    if (size === "md") {
      return tw`grid-cols-4 gap-2-4`
    }
    if (size === "lg") {
      return tw`grid-cols-7 gap-1-6`
    }
    if (size === "xl") {
      return tw`grid-cols-3 gap-1-6`
    }
  }}
`

type IconsProp = {
  size: "sm" | "md" | "lg" | "xl"
}

export const Styleguide = (): JSX.Element => (
  <Container width={"md"}>
    <H1 spacing={"default"}>Heading 1 over two lines</H1>
    <H2 spacing={"default"}>Heading 2 over two lines</H2>
    <H3 spacing={"default"}>Heading 3 over two lines</H3>
    <H4 spacing={"default"}>Heading 4 over two lines</H4>
    <H5 spacing={"default"}>Heading 5 over two lines</H5>
    <H6 spacing={"default"}>Heading 6 over two lines</H6>
    <SubTitle spacing={"default"}>subtitle over two lines</SubTitle>
    <XXLargeBody spacing={"default"}>
      body XXlarge / 25 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse
      tristique arcu erat, a condimentum ante mollis ut. Suspendisse tristique
      malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus
      dapibus, in facilisis nibh bibendum. Donec imperdiet viverra sapien, et
      pulvinar velit elementum at.
    </XXLargeBody>
    <XLargeBody spacing={"default"}>
      body XLarge / 20 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse
      tristique arcu erat, a condimentum ante mollis ut. Suspendisse tristique
      malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus
      dapibus, in facilisis nibh bibendum. Donec imperdiet viverra sapien, et
      pulvinar velit elementum at.
    </XLargeBody>
    <LargeBody spacing={"default"}>
      body large / 18 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse
      tristique arcu erat, a condimentum ante mollis ut. Suspendisse tristique
      malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus
      dapibus, in facilisis nibh bibendum. Donec imperdiet viverra sapien, et
      pulvinar velit elementum at.
    </LargeBody>
    <Body spacing={"default"}>
      body reg / 16 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse
      tristique arcu erat, a condimentum ante mollis ut. Suspendisse tristique
      malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus
      dapibus, in facilisis nibh bibendum. Donec imperdiet viverra sapien, et
      pulvinar velit elementum at.
    </Body>
    <SmallBody spacing={"default"}>
      body small / 14 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse
      tristique arcu erat, a condimentum ante mollis ut. Suspendisse tristique
      malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus
      dapibus, in facilisis nibh bibendum. Donec imperdiet viverra sapien, et
      pulvinar velit elementum at.
    </SmallBody>
    <TinyBody spacing={"default"}>
      body tiny / 12 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Maecenas molestie tellus lacus, a tincidunt nibh molestie eu. Suspendisse
      tristique arcu erat, a condimentum ante mollis ut. Suspendisse tristique
      malesuada orci fringilla consectetur. Praesent rhoncus mauris non metus
      dapibus, in facilisis nibh bibendum. Donec imperdiet viverra sapien, et
      pulvinar velit elementum at.
    </TinyBody>

    <StyledRule size={"default"} spacing={"default"} />

    <H6 spacing={"default"}>Colour</H6>

    <Colours>
      <Swatch colour={`navy`}>
        <figcaption>navy</figcaption>
      </Swatch>
      <Swatch colour={`navy-light`}>
        <figcaption>navy-light</figcaption>
      </Swatch>
      <Swatch colour={`white`}>
        <figcaption>white</figcaption>
      </Swatch>
      <Swatch colour={`red`}>
        <figcaption>Red</figcaption>
      </Swatch>
      <Swatch colour={`grey`}>
        <figcaption>grey</figcaption>
      </Swatch>
      <Swatch colour={`grey-light`}>
        <figcaption>grey-light</figcaption>
      </Swatch>
    </Colours>

    <StyledRule size={"default"} spacing={"default"} />

    <H6 spacing={"default"}>Icons</H6>
    <Icons size={"sm"}>
      <Icon name={`account`} />
      <Icon name={`cart`} />
      <Icon name={`search`} />
      <Icon name={`wishlist`} />
      <Icon name={`arrow`} />
      <Icon name={`chevron`} />
      <Icon name={`cross`} />
      <Icon name={`plus`} />
      <Icon name={`quickView`} />
      <Icon name={`marker`} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />
    <Icons size="sm">
      <Icon name={`masterCard`} width={"40"} height={"14"} />
      <Icon name={`american`} width={"40"} height={"14"} />
      <Icon name={`applePay`} width={"40"} height={"14"} />
      <Icon name={`visa`} width={"40"} height={"14"} />
      <Icon name={`paypal`} width={"40"} height={"14"} />
      <Icon name={`afterPay`} width={"80"} height={"15"} />
      <Icon name={`zip`} width={"40"} height={"14"} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />
    <Icons size={"md"}>
      <Icon name={`giftCard`} />
      <Icon name={`delivery`} />
      <Icon name={`returns`} />
      <Icon name={`liveChat`} />
    </Icons>

    <StyledRule size={"default"} spacing={"default"} />

    <H6 spacing={"default"}>Button</H6>

    <div>
      <Button colour={"navyFilledWithWhiteText"} size={"primary"}>
        Button
      </Button>

      <Button colour={"redFilledWithWhiteText"} size={"primary"}>
        Button
      </Button>

      <Button colour={"blackBorderedWithBlackText"} size={"primary"}>
        Button
      </Button>
    </div>
    <div style={{ backgroundColor: "#0C2340" }}></div>
    <div
      style={{
        backgroundColor: "#766D89",
      }}
    >
      <Button colour={"whiteFilledWithNavyText"} size={"primary"}>
        Button
      </Button>
      <Button colour={"whiteFilledWithRedText"} size={"primary"}>
        Button
      </Button>
      <Button colour={"whiteBorderedWithWhiteText"} size={"primary"}>
        Button
      </Button>
      <Button colour={"whiteBorderedWithWhiteTextHoverNavy"} size={"primary"}>
        Button
      </Button>
    </div>
    <div>
      <Button colour={"navyFilledWithWhiteText"} size={"primary"}>
        Button
      </Button>
      <Button colour={"navyFilledWithWhiteText"} size={"secondary"}>
        Button
      </Button>
    </div>
    <div style={{ margin: "16px" }}>
      <UnderlinedButton size={"primary"}>
        <span>Optional Link</span>
      </UnderlinedButton>
    </div>
    <div style={{ margin: "16px" }}>
      <UnderlinedButton size={"secondary"}>
        <span>Optional Link</span>
      </UnderlinedButton>
    </div>
  </Container>
)
