import tw, { styled } from "twin.macro"

const colours = {
  navy: tw`bg-navy text-white`,
  "navy-light": tw`bg-navy-light`,
  white: tw`bg-white border`,
  red: tw`bg-red`,
  grey: tw`bg-grey`,
  "grey-light": tw`bg-grey-light`,
}

export const Swatch = styled.figure<SwatchProps>`
  ${tw`w-14 h-14 m-1-6 rounded-full flex items-center justify-center`}
  ${({ colour }) => colour && colours[colour]}
`

type SwatchProps = {
  colour: "navy" | "navy-light" | "white" | "red" | "grey" | "grey-light"
}
